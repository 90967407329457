"use client";

import type { FaqOverviewResult } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { FaqItem } from "@/components/molecules/FaqItem";
import { CategorizedFaqItem } from "@/components/molecules/CategorizedFaqItem";
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { Button, Skeleton } from 'antd';
import { useTransition, useState, useEffect, useCallback } from 'react';
import Icon from "@/components/atoms/Icon";

interface FaqOverviewListProps {
  className?: string;
  results: FaqOverviewResult[];
  merken: { tid: string; name: string }[];
  labels: { tid: string; name: string }[];
}

export const FaqOverviewList = ({
                                  results = [],
                                  className,
                                  merken = [],
                                  labels = [],
                                }: FaqOverviewListProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();
  const [activeFilters, setActiveFilters] = useState<{ zoeken: string | null; merken: string | null; labels: string[] }>({ zoeken: null, merken: null, labels: [] });
  const [searchInputValue, setSearchInputValue] = useState<string | null>(null);

  useEffect(() => {
    setActiveFilters({
      zoeken: searchParams.get('zoeken'),
      merken: searchParams.get('merken'),
      labels: searchParams.get('labels') ? searchParams.get('labels').split(',') : [],
    });
    setSearchInputValue(searchParams.get('zoeken'));
  }, [searchParams]);

  const updateFilters = useCallback((params) => {
    startTransition(() => {
      const newParams = new URLSearchParams(searchParams.toString());
      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            newParams.set(key, value.join(','));
          } else {
            newParams.delete(key);
          }
        } else if (value) {
          newParams.set(key, value.toString());
        } else {
          newParams.delete(key);
        }
      });
      router.push(`${pathname}?${newParams.toString()}`);
      setActiveFilters(params);
    });
  }, [router, searchParams, pathname]);

  const handleFilterChange = useCallback((event) => {
    const { name, value } = event.target;
    const newFilters = {
      ...activeFilters,
      [name]: name === 'labels' ? (value ? [value] : []) : value || null,
    };
    setActiveFilters(newFilters);
    updateFilters(newFilters);
  }, [activeFilters, updateFilters]);

  const clearFilters = useCallback(() => {
    updateFilters({ zoeken: null, merken: null, labels: [] });
  }, [updateFilters]);

  const filtersActive = activeFilters.zoeken !== null || activeFilters.merken !== null || activeFilters.labels.length > 0;
  // Categorize results by labels if no filters are active
  const categorizedResults = filtersActive ? null : labels.reduce((acc, label) => {
    acc[label.tid] = {
      name: label.name,
      faqs: results.filter(result => (result.labels || []).some(resultLabel => resultLabel.tid === label.tid))
    };
    return acc;
  }, {});

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    updateFilters({ ...activeFilters, zoeken: searchInputValue });
  }, [activeFilters, updateFilters, searchInputValue]);

  return (
    <>
      <div className="mb-12 lg:mb-24" itemScope itemType="https://schema.org/FAQPage">
        <div className="flex flex-col gap-4 mb-2 md:mb-8">
          <form className="w-full flex flex-col" onSubmit={handleSubmit}>
            <div className="flex flex-col sm:flex-row w-full mb-4 lg:mb-6 gap-2">
              <label className={"sr-only"} htmlFor={"zoeken"}>Zoek in veelgestelde vragen</label>
              <input
                type="text"
                id="zoeken"
                name="zoeken"
                autoComplete="off"
                placeholder="Zoek in veelgestelde vragen..."
                className="input-default input-primary w-full lg:w-screen lg:max-w-[650px] rounded"
                value={searchInputValue || ""}
                onChange={(e) => setSearchInputValue(e.target.value)}
              />
              <button type="submit" className="btn btn-primary text-white">
                Zoeken
                <Icon name="search" className="fill-white h-4 w-4" />
              </button>
            </div>

            {(merken.length > 0 || labels.length > 0) && (
              <div className={"flex flex-col gap-4 mb-4"}>
                <p>Filter de veelgestelde resultaten</p>
                <div className={"flex flex-col lg:flex-row gap-4 lg:gap-2"}>
                  <div className="flex flex-col sm:flex-row sm:flex-1 gap-2">
                    {merken.length > 0 && (
                      <select
                        name="merken"
                        autoComplete="off"
                        className="select-default select-primary pr-10 lg:max-w-[200px]"
                        value={activeFilters.merken || ""}
                        onChange={handleFilterChange}
                      >
                        <option value="">Alle merken</option>
                        {merken.map(merk => (
                          <option key={merk.tid} value={merk.tid}>{merk.name}</option>
                        ))}
                      </select>
                    )}
                    {labels.length > 0 && (
                      <select
                        name="labels"
                        autoComplete="off"
                        className="select-default select-primary pr-10 lg:max-w-[200px] animate-[fadeIn_both_.3s]"
                        value={activeFilters.labels[0] || ""}
                        onChange={(e) => handleFilterChange(e)}
                      >
                        <option value="">Alle onderwerpen</option>
                        {labels.map(label => (
                          <option key={label.tid} value={label.tid}>{label.name}</option>
                        ))}
                      </select>
                    )}
                  </div>
                  {filtersActive && (
                    <Button onClick={clearFilters} className="btn btn-ghost border-grey/10 p-2.5 hover:!bg-red hover:!border-red focus:text-white animate-[fadeIn_both_.3s] group lg:ml-auto max-lg:mr-auto">
                      <Icon name="close" className="h-2 w-2 group-hover:rotate-180 transition-transform" />
                      Verwijder alle filters
                    </Button>
                  )}
                </div>
              </div>
            )}
          </form>
        </div>

        {results.length > 0 ? (
          <>
            {isPending ? (
              <div className={cn('faq-list-items flex flex-col gap-4', className)}>
                {Array.from({ length: 8 }).map((_, index) => (
                  <div key={index} className="flex flex-col gap-4 border-b border-b-grey/45 py-4">
                    <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
                  </div>
                ))}
              </div>
            ) : filtersActive ? (
              <div className={cn('faq-list-items flex flex-col gap-4 mb-8', className)}>
                {results.map((item, index) => (
                  <div key={item.id} className="flex flex-col gap-4 animate-[fadeIn_both_.3s]" style={{ animationDelay: `${index * 0.015}s` }}>
                    <FaqItem item={item} />
                  </div>
                ))}
              </div>
            ) : (
              categorizedResults && Object.entries(categorizedResults).map(([labelId, { name, faqs }], index) => {
                return (
                  <CategorizedFaqItem
                    key={labelId}
                    labelId={labelId}
                    name={name}
                    faqs={faqs}
                    defaultActive={false}
                  />
                );
              })
            )}
          </>
        ) : (
          <div className="text-red-500 mt-6">
            Er zijn geen resultaten gevonden. Probeer een andere combinatie van filters.
          </div>
        )}
      </div>
    </>
  );
};
