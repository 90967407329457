"use client";

import type { NewsOverviewResult } from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { TeaserNews } from "@/components/molecules/TeaserNews";
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { Pagination, Skeleton, Button } from 'antd';
import { useTransition, useState, useEffect, useCallback } from 'react';
import Icon from "@/components/atoms/Icon";

interface NewsOverviewListProps {
  className?: string;
  results: NewsOverviewResult[];
  pageInfo: {
    total: number;
    page: number;
    pageSize: number;
  };
  themas: { tid: string; name: string }[];
  merken: { tid: string; name: string }[];
  renderedParagraphOne: React.ReactNode;
  renderedParagraphTwo: React.ReactNode;
  renderedParagraphThree: React.ReactNode;
}

export const NewsOverviewList = ({
                                   results = [],
                                   className,
                                   pageInfo,
                                   themas = [],
                                   merken = [],
                                   renderedParagraphOne,
                                   renderedParagraphTwo,
                                   renderedParagraphThree,
                                 }: NewsOverviewListProps) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [isPending, startTransition] = useTransition();
  const [activeFilters, setActiveFilters] = useState<{ themas: string | null; merken: string | null }>({ themas: null, merken: null });

  useEffect(() => {
    setActiveFilters({
      themas: searchParams.get('themas'),
      merken: searchParams.get('merken'),
    });
  }, [searchParams]);

  const updateFilters = useCallback((params) => {
    startTransition(() => {
      const newParams = new URLSearchParams(searchParams.toString());

      Object.entries(params).forEach(([key, value]) => {
        if (value) {
          newParams.set(key, value);
        } else {
          newParams.delete(key);
        }
      });

      // Reset the pagination to the first page
      newParams.set('pagina', '0');

      router.push(`${pathname}?${newParams.toString()}`);
      setActiveFilters(params);
    });
  }, [router, searchParams, pathname]);

  const handleFilterChange = useCallback((event) => {
    const { name, value } = event.target;
    updateFilters({ ...activeFilters, [name]: value || null });
  }, [activeFilters, updateFilters]);

  const handlePageChange = useCallback((page) => {
    startTransition(() => {
      const newParams = new URLSearchParams(searchParams.toString());
      newParams.set('pagina', (page - 1).toString());
      router.push(`${pathname}?${newParams.toString()}`);
    });
  }, [router, searchParams, pathname]);

  const clearFilters = useCallback(() => {
    updateFilters({ themas: null, merken: null });
  }, [updateFilters]);

  const filtersActive = activeFilters.themas !== null || activeFilters.merken !== null;

  return (
    <>
      <div className="mb-12 lg:mb-24">
        <div className="flex flex-col lg:flex-row gap-4 mb-8">
          {(merken.length > 0 || themas.length > 0) && (
            <form className="max-lg:flex-1 w-full">
              <div className="flex gap-4">
                {merken.length > 0 && (
                  <select
                    name="merken"
                    className="select-default select-primary pr-10 lg:max-w-[200px]"
                    value={activeFilters.merken || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">Alle merken</option>
                    {merken.map(merk => (
                      <option key={merk.tid} value={merk.tid}>{merk.name}</option>
                    ))}
                  </select>
                )}

                {themas.length > 0 && (
                  <select
                    name="themas"
                    className="select-default select-primary pr-10 lg:max-w-[200px]"
                    value={activeFilters.themas || ""}
                    onChange={handleFilterChange}
                  >
                    <option value="">Alle thema's</option>
                    {themas.map(thema => (
                      <option key={thema.tid} value={thema.tid}>{thema.name}</option>
                    ))}
                  </select>
                )}
              </div>
            </form>
          )}
          {filtersActive && (
            <Button onClick={clearFilters} className="btn btn-ghost border-grey/10 p-2.5 hover:!bg-red hover:!border-red animate-[fadeIn_both_.3s] group">
              <Icon name="close" className="h-2 w-2 group-hover:rotate-180 transition-transform" />
              Verwijder alle filters
            </Button>
          )}
        </div>

        <div>
          {isPending ? (
            <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6', className)}>
              {Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="flex flex-col gap-4 bg-grey-100/80 p-6 rounded-xl">
                  <Skeleton.Avatar active size="large" shape="square" />
                  <Skeleton active />
                </div>
              ))}
            </div>
          ) : (
            <div>
              {results.length > 0 ? (
                <>
                  {!filtersActive && pageInfo.page === 0 && ( renderedParagraphOne ?? renderedParagraphTwo ?? renderedParagraphThree) ? (
                    <div>
                      <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6', className)}>
                        {results.slice(0, 8).map((item, index) => (
                          <div
                            key={item.id}
                            className="flex flex-col gap-4 animate-[fadeIn_both_.3s]"
                            style={{ animationDelay: `${index * 0.015}s` }}
                          >
                            <TeaserNews
                              title={item.title}
                              thema={item.thema}
                              media={item.teaserMedia?.imageMediaImage ?? undefined}
                              link={{
                                url: item.path,
                                internal: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {renderedParagraphOne && <div className={"mt-10 lg:mt-24 max-lg:hidden"}>{renderedParagraphOne}</div>}
                      <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6', className)}>
                        {results.slice(8, 16).map((item, index) => (
                          <div
                            key={item.id}
                            className="flex flex-col gap-4 animate-[fadeIn_both_.3s]"
                            style={{ animationDelay: `${index * 0.015}s` }}
                          >
                            <TeaserNews
                              title={item.title}
                              thema={item.thema}
                              media={item.teaserMedia?.imageMediaImage ?? undefined}
                              link={{
                                url: item.path,
                                internal: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {renderedParagraphTwo && <div className={"mt-10 lg:mt-24 max-lg:hidden"}>{renderedParagraphTwo}</div>}
                      <div className={cn(
                        'blog-list-items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6',
                        className,
                        !renderedParagraphTwo ? 'mt-6 lg:mt-10' : ''
                      )}>
                        {results.slice(16, 24).map((item, index) => (
                          <div
                            key={item.id}
                            className="flex flex-col gap-4 animate-[fadeIn_both_.3s]"
                            style={{ animationDelay: `${index * 0.015}s` }}
                          >
                            <TeaserNews
                              title={item.title}
                              thema={item.thema}
                              media={item.teaserMedia?.imageMediaImage ?? undefined}
                              link={{
                                url: item.path,
                                internal: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {renderedParagraphThree && <div className={"mt-10 lg:mt-24 max-lg:hidden"}>{renderedParagraphThree}</div>}
                      <div className={cn(
                        'blog-list-items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6',
                        className,
                        !renderedParagraphThree ? 'mt-6 lg:mt-10' : ''
                      )}>
                        {results.slice(24, 32).map((item, index) => (
                          <div
                            key={item.id}
                            className="flex flex-col gap-4 animate-[fadeIn_both_.3s]"
                            style={{ animationDelay: `${index * 0.015}s` }}
                          >
                            <TeaserNews
                              title={item.title}
                              thema={item.thema}
                              media={item.teaserMedia?.imageMediaImage ?? undefined}
                              link={{
                                url: item.path,
                                internal: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={cn('blog-list-items grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 grid lg:gap-y-10 lg:gap-x-6', className)}>
                        {results.map((item, index) => (
                          <div
                            key={item.id}
                            className="flex flex-col gap-4 animate-[fadeIn_both_.3s]"
                            style={{ animationDelay: `${index * 0.015}s` }}
                          >
                            <TeaserNews
                              title={item.title}
                              thema={item.thema}
                              media={item.teaserMedia?.imageMediaImage ?? undefined}
                              link={{
                                url: item.path,
                                internal: true,
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {pageInfo && pageInfo.total / pageInfo.pageSize > 1 && (
                    <Pagination
                      total={pageInfo.total}
                      current={pageInfo.page + 1}
                      pageSize={pageInfo.pageSize}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      className="number-pagination !mt-6 lg:mt-12 xl:!mt-14 w-full inline-flex justify-center mx-auto"
                    />
                  )}
                </>
              ) : (
                <div className="text-red-500 mt-6">
                  Er zijn geen resultaten gevonden. Probeer een andere combinatie van filters.
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};