import { useState } from "react";
import cn from "classnames";
import { FaqItem } from "./FaqItem";
import Icon from "@/components/atoms/Icon"; // Adjust the import path as needed

const CategorizedFaqItem = ({ labelId, name, faqs, defaultActive }) => {
  const [isActive, setIsActive] = useState(defaultActive);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={cn("categorized-faq-item mb-4 lg:mb-8", { "active": isActive })} key={labelId}>
      <div>
        <button
          className={cn("btn w-full flex gap-4 justify-start border-b rounded-none border-b-grey/20 hover:border-b-blue pb-2 font-semibold px-0 transition-colors text-left group", { "": isActive })}
          aria-expanded={isActive}
          onClick={handleToggle}
        >
          <span className={"h4 !m-0 !leading-none"}>{name || 'Unknown Label'}</span>
          <Icon
            name="arrow-vrt"
            className={cn("h-5 w-5 min-w-[1.25rem] md:h-6 md:w-6 md:min-w-[1.5rem] ml-auto transition-transform", isActive ? "rotate-[-90deg]" : "rotate-90")}
          />
        </button>
        <div
          role="region"
          aria-labelledby={`faq-button-${labelId}`}
          className={cn("categorized-faq-item__content pt-2 flex-col", { "flex animate-[fadeIn_both_.3s]": isActive, "hidden": !isActive })}
        >
          <div className="faq-list-items flex flex-col">
            {faqs.map((item, index) => (
              <div key={item.id} className="flex flex-col gap-4 animate-[fadeIn_both_.3s]" style={{ animationDelay: `${index * 0.015}s` }}>
                <FaqItem item={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CategorizedFaqItem };
