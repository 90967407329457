import {useState} from "react";
import {cn} from "@/helpers/className";
import Image from "next/image";
import {getVariation} from "@/helpers/image.ts";
import {ImageStyleAvailable} from "@/graphql/sdk/__generated__";

interface FaqItemProps {
  item: {
    id: string;
    title: string;
    answer: {
      processed: string;
    };
  };
}

const FaqItem = ({ item }: FaqItemProps) => {
  const [isActive, setIsActive] = useState(false);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const image = item?.media?.imageMediaImage ? getVariation(item?.media?.imageMediaImage.variations, ImageStyleAvailable.BannerRatio21) : null;
  return (
    item.answer.processed && (
      <div className={cn("faq-item", `${isActive ? "active" : ""}`)} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
        <div>
          <button
            className={cn("btn w-full justify-start border-b rounded-none border-b-grey/20 hover:border-b-blue py-3 font-normal px-0 !m-0 transition-colors text-left group", `${isActive ? "text-blue border-b-blue" : ""}`)}
            itemProp="name"
            aria-expanded={isActive}
            id={`faq-button-${item.id}`}
            aria-controls={`faq-content-${item.id}`}
            onClick={handleToggle}
          >
            {item.title}
            <svg
              className={cn("shrink-0 ml-auto transition-colors group-hover:fill-blue md:mr-[.3rem]", `${isActive ? "fill-blue" : "fill-grey"}`)}
              width="16"
              height="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="7"
                width="16"
                height="2"
                rx="1"
                className={`transform origin-center transition duration-200 ease-out ${isActive ? '!rotate-180' : ''}`}
              />
              <rect
                y="7"
                width="16"
                height="2"
                rx="1"
                className={`transform origin-center rotate-90 transition duration-200 ease-out ${isActive ? '!rotate-180' : ''}`}
              />
            </svg>
          </button>
          <div
            id={`faq-content-${item.id}`}
            role="region"
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
            aria-labelledby={`faq-button-${item.id}`}
            className={cn("faq-item__content py-4 flex-col prose max-w-none", `${isActive ? "flex animate-[fadeIn_both_.3s]" : "hidden"}`)}
          >
            <div itemProp="text">
              <div dangerouslySetInnerHTML={{ __html: item.answer.processed }} />
              {image && (
                  <Image
                      className="w-auto rounded-xl overflow-hidden mx-auto"
                      src={image.url}
                      alt={item?.media?.imageMediaImage?.alt ?? ''}
                      loading={"lazy"}
                      width={image.width}
                      height={image.height}
                      quality={90}
                      sizes={"(min-width: 1024px) 50vw, 100vw"}
                  />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export { FaqItem };
